#postForm {
    background-color:#242628;
    border-color: rgb(244, 129, 32);
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
    margin: 5px 5px;
    padding: 10px 10px;
    color: #fafafa;
}

.field {
    width: 200px;
    
}

.row {
    width: 100px;
    display: inline-block;
}

.submitButton {
    width: 100px;
    height: 20px;
    color: black;
}