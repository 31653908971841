.App {
  text-align: center;
  height: max-content;
}

html, body {
  height: 100%;
  background-color: #191b1d
}

#container {
  width: 600px;
  margin: 0 auto;
}

#title {
  color: rgb(244, 129, 32);
  text-align: center;
  font-size: 50px;
}

.post-body {
  background-color:#242628;
  border-color: rgb(244, 129, 32);
  border-width: 2px;
  border-style: solid;
  border-radius: 20px;
  margin: 5px 5px;
  padding: 10px 10px;
  color: #fafafa;
}

.post-content {
  font-style: italic;
}